/* .item must be first */
.item {
  align-items: center;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  height: 40px;
  justify-content: center;
  width: 40px;
}

.disabled-page {
  color: #808e9b;
}

.active {
  border: solid 1px #808e9b;
  border-radius: 40px;
  /* color: #808e9b; */
  color: #000000;
  font-weight: 900;
  background-color: white;
}

.break-me {
}

.next {
  border-left: solid 1px #ffffff;
  font-size: 4px;
  height: 60px;
  position: absolute;
  right: 0;
  width: 100px;
}

.pagination {
  align-items: center;
  background-color: #0fbcf9;
  display: flex;
  flex-direction: row;
  /* height: 60px; */
  justify-content: center;
  list-style-type: none;
  position: relative;
  width: 1000px;
}

.pagination-page {
  font-weight: 700;
}

.previous {
  border-right: solid 1px #ffffff;
  font-size: 4px;
  height: 60px;
  left: 0;
  position: absolute;
  width: 100px;
}
