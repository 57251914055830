@import "./shared/colors";
@import "./shared/mixings";

// PAGES
@import "./index";
@import "./blogs";
@import "./portfolios";
@import "./about";
@import "./cv";
// COMPONENTS
@import "./header";
@import "./slate-editor";

html,
body {
  height: 100%;
  width: 100%;
  // top: 0;
  font-family: "Montserrat", "Helvetica";
}

.clickable {
  cursor: pointer;
}

.self-typed {
  font-size: 31px;
  font-weight: bold;
  letter-spacing: 2px;
  color: #ffffff;
}

.absolute {
  position: absolute;
}
.error {
  font-weight: 700;
  color: red;
}

.base-page {
  padding: 0px 0 0px 0;
  color: black;
  background-color: #2ebc1b;
  min-height: 540px;

  .page-header {
    .page-header-title {
      font-weight: 700;
      color: black;
      display: inline-block;
      margin-bottom: 20px;
    }
  }
}

.base-page-blue {
  transition: 0.5s;
  background-color: cornflowerblue;
}
.base-page-green {
  transition: 0.5s;
  background-color: #2ebc1b;
}
.base-page-red {
  transition: 0.5s;
  background-color: #dc3545;
}
.base-page-indigo {
  transition: 0.5s;
  background-color: #6610f2;
}
.base-page-pink {
  transition: 0.5s;
  background-color: #e83e8c;
}
.base-page-purple {
  transition: 0.5s;
  background-color: #6f42c1;
}
.base-page-orange {
  transition: 0.5s;
  background-color: #fd7e14;
}
.base-page-yellow {
  transition: 0.5s;
  background-color: #ffc107;
}
.base-page-teal {
  transition: 0.5s;
  background-color: #0085a1;
}
