.controll-menu {
  text-align: right;
  border-bottom: 1px solid gray;
  padding-bottom: 20px;
  margin-bottom: 20px;

  .title {
    float: left;
    font-weight: bold;
  }

  .status-box {
    display: inline-block;
    margin-right: 10px;
  }
}
