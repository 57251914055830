// INDEX PAGE
.cover {
  background-image: linear-gradient(45deg, #00aeef 0%, #096fb9 100%);
  position: relative;
}

.wrapper {
  min-height: 100vh;
  min-width: 100vw;
  margin-top: 0;
  position: relative;
}

.main-section {
  top: 0px;
  width: 100%;
  padding: 0px 0 0;
}

.hero-section {
  h2 {
    color: white;
    font-weight: bold;
    margin-bottom: 10px;
  }

  perspective: 1000px;
  color: white;
  width: 400px;
  position: relative;

  &-content {
    position: absolute;
    bottom: 40px;
    width: 360px;
    left: 6%;
    z-index: 1;

    &-intro {
      font-size: 17px;
    }
  }
}

.image {
  width: auto;
  max-width: 100%;
}

.hero-welcome-text > h1 {
  color: white;
  text-transform: uppercase;
  font-size: 27px;
  margin-top: 40px;
}

.hero-welcome-bio {
  margin-top: 80px;
  color: white;

  .fa-stack {
    font-size: 28px;
  }

  .list-inline-item {
    > a {
      color: #373737;
    }
  }
}

.hero-welcome-wrapper {
  justify-content: flex-end;
  z-index: 2000;
}

@media (max-width: 991px) {
  .hero-section {
    width: 341px;
    height: 445px;

    &-content {
      width: 341px;
    }
  }

  .hero-welcome-text > h1 {
    margin-top: 0px;
  }
}

.background-image {
  position: absolute;
  width: 1762px;
  height: 493px;
}

.shadow-custom {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  box-shadow: -17px 13px 41px rgba(13, 78, 158, 0.6);
  border: 2px solid #3bace5;

  .shadow-inner {
    width: 100%;
    height: 100%;
    box-shadow: -17px 13px 13px rgba(0, 0, 0, 0.09);
  }
}

.shadow-custom-2 {
  border: 2px solid #f37d49;
  box-shadow: -17px 13px 41px rgba(13, 78, 158, 0.3);
}

// Animations background front page
// .cover-0 {
// }

.cover-1 {
  background-image: linear-gradient(
    45deg,
    rgb(254, 202, 83),
    rgb(253, 157, 97)
  );
}

// Animations card front page

.flipper {
  transition: 1s;
  transform-style: preserve-3d;
  position: relative;
}
.flipper.isFlipping {
  transform: rotateY(-180deg);
}

.front,
.back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.front {
  transform: rotateY(0deg);
  z-index: 1000;
}

.back {
  transform: rotateY(180deg);
}
