.about-page {
  .left-side {
    margin-top: 10px;

    color: black;
    text-align: left;
  }

  .fadein {
    opacity: 0;

    @include animation(fadein 2s);
    @include animation-fill-mode(forwards);
  }

  .title {
    @include animation-delay(0.7s);
    font-size: 42px;
    font-weight: bold;
  }

  .subtitle {
    @include animation-delay(2.2s);
    font-size: 33px;
  }

  .subsubTitle {
    @include animation-delay(3.6s);
    font-size: 18px;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
